.socialNetwork > a{
    color:white;

    text-decoration: none;
    /* color:gray; */
}
.socialNetwork > a:hover{
    color:#e72894;

    text-decoration: none;
    /* color:gray; */
}
.socialNetwork{
    text-align:center;
    height:40px;
    vertical-align: middle;
}
.icons{
    margin-right: 10px;
}

.socialNetwork-items{
    margin-top : 20px;
    margin-bottom : 20px;
}

#socialNetwork{
    /* margin-top:20px; */
    color:white;
    background: linear-gradient(to left, #26B1E0,#5B3A91);
}