#Banner-Pic{
    opacity:0.65;
}
@media  (min-width : 600px){
    #Banner{
        /* background-image: url('../../Assets/banner1.gif'),radial-gradient(#26B1E0 75%,#5B3A91 ); */
        background-image: url('../../assets/devenezFormateur.jpeg')/*,radial-gradient(#26B1E0 75%,#5B3A91 )*/;
        background-size: cover;
        background-repeat: no-repeat;
        height:80vh;
    }
}
@media  (max-width : 600px){
    #Banner{
        /* background-image: url('../../Assets/banner1.gif'),radial-gradient(#26B1E0 75%,#5B3A91 ); */
        background-image: url('../../assets/devenezFormateur.jpeg')/*,radial-gradient(#26B1E0 75%,#5B3A91 )*/;
        background-size: cover;
        background-repeat: no-repeat;
        width:100%;
        height:40vh;
        margin:0;
    }
}
@media  (min-width : 600px){
    #Banner-Title{
        /* position:relative;*/
        /* top:-3em;  */
        /* color:aliceblue; */
        /* margin-top:1em; */
        margin-bottom:1em;
        background-color: RGB(55,98,135,0.75);
        width : 100%;
        /* font-size: 20px; */
    }
    #Banner-Title-1{
        padding-left: 50px;
        
        color:white;
        font-family: "Gill Sans";
        font-size: 2vw;
    }
    #Banner-Title-2{
        color:white;
        font-family: "Gill Sans";
        font-size: 2vw;
        padding-left: 100px;
    }
}
@media  (max-width : 600px){
    #Banner-Title{
    /* position:relative;*/
    /* top:-3em;  */
    /* color:aliceblue; */
    /* margin-top:1em; */
    margin-bottom:1em;
    background-color: RGB(55,98,135,0.75);
    width : 100%;
    /* font-size: 20px; */
    }
    #Banner-Title-1{
        
        color:white;
        font-family: "Gill Sans";
        /* font-size: 2vw; */
    }
    #Banner-Title-2{
        color:white;
        font-family: "Gill Sans";
        /* font-size: 2vw; */
    }
}