.ContactezNous {
    /* height:300px; */
    margin-top:20px;
    margin-bottom:20px;
}

.ContactezNous-Text{
    color:white;
    text-align:center;
    background-color: rgb(110, 189, 239);
    border-top : 1px solid #6EBDEF;
    border-bottom : 1px solid #6EBDEF;
    border-left : 1px solid #6EBDEF;
}
.NousContacter-Title{
    margin-left:0px;
    margin-right:0px;
}
#Inscrire{
    background-image: url("../../assets/rejoindreLEcole.jpeg");
    background-repeat: no-repeat;
    background-size: contain;
    min-height:200px;
    
}
#DevenirFormateur{
    background-image: url("../../assets/devenezFormateur.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
}