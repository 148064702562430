.NousContacter-Title{
    background: linear-gradient(to left, #26B1E0,#5B3A91);
    color:aliceblue;
    text-align:center;
    margin-bottom:20px;
}

.NousContacter-Title > h5 {
    margin-bottom:0px;
}

img{
    border-radius: 5% 5% 5% 5%;
}

.cat-number{
    color : rgb(44, 153, 236);
    font-size: 5em;
}
.cat-title{
    font-size: 3em;
    
}
.condition-corps{
    font-size: 1.5em;
    
}
.condition-titre{
    color : rgb(44, 153, 236);
    font-size: 1.5em;

}