.legende{
    /* margin-bottom : 40px; */
    position:relative;
    top:-3em;
    color:aliceblue;
    background-color: RGB(55,98,135,0.75);
    width : 100%;
    font-size: 20px;
}

.legende > h5{
    padding-left : 10px;
    /* margin-bottom : 20px; */
}

.presentation-block  a {
    text-decoration: none;
    
}
.presentation-block:hover .legende{
    color:#ddc8ff;
}


img{
    width:100%;
}