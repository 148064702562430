.center-text{
  width:100%;
  text-align: center;
  align-items: center;
  margin-top:10px;
  margin-bottom:10px;
  font-size:3vh;
  /* border :1px solid black; */
}

.andn-text{
  font-weight: bold;
  background: linear-gradient(120deg,RGB(99,40,148) 0%,RGB(50, 153,214) 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

*{
  font-family : 'Montserrat';
}

h5{
  font-family: "Montserrat";
  padding-top:10px;
  padding-bottom:10px;
}